//

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.eot#") format("eot"),
  url("../fonts/Roboto-Light.woff") format("woff"),
  url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}


$font-rbt: 'Roboto';
$font-rbt-r: 'Roboto-Regular';
