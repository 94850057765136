@include media-breakpoint-down (md) {
  .p-home {
    section {
      margin-top: 0;
      margin-bottom: 30px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 80px;
      }
    }

    .home__top-swiper {

      &__item {

        height: 450px;
        width: 100%;
        @media (max-width: 576px) {
          height: 250px;
        }
        @media (max-width: 480px) {
          height: 220px;
        }
        @media (max-width: 410px) {
          height: 200px;
        }
        @media (max-width: 380px) {
          height: 180px;
        }
        .img {
          img {
            @include media-breakpoint-down (sm) {
              height: 450px;
              width: 100%;
            }
            @media (max-width: 576px) {
              height: 250px;
            }
            @media (max-width: 480px) {
              height: 220px;
            }
            @media (max-width: 410px) {
              height: 200px;
            }
            @media (max-width: 380px) {
              height: 180px;
            }
          }
        }
        &-header {
          font: {
            size: 36px;
          }
          color: $defWhiteCol !important;
          padding-top: 30px;;
          padding-left: 20px;;
        }
        &-caption {
          color: $defWhiteCol !important;
          width: 75%;
          font-size: 18px;
        }
      }

      .swiper-button {
        &-next, &-prev {
          display: none;
        }
      }

    }
    .product {
      margin-top: 0;
      margin-bottom: 30px;

      .swiper-pagination-bullet {
        background-color: $defBlueCol;
        &-active {
          background-color: $defGreenCol;
        }
      }
    }
    .equipment {
      &-item {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;

        &:not(:first-child) {
          display: none;
        }
      }
    }

    .feature{
      margin-top: 0;
      margin-bottom: 30px;
      .photo{
        background: none !important;
        height: auto;
        width: auto;
        padding: 0 15px 50px 15px;
        margin: 0;
        .text{
          position: relative;
          top:auto;
          right: auto;
          height: auto;
          width: 100%;
        }
      }
      >.container>&-list{
        display: none;
      }
      &-list{
        margin-bottom: 15px;
      }
      .swiper{
        padding-bottom: 30px;
      }

    }

    .subs{

    }
    .review{
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
}
