
.header {
  a {
    @include setLinkColor();
  }

  .glyphicon {
    margin-right: 8px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  //margin-right: -15px; //
  //margin-left: -15px;
  background: #1e69b0;
  color: $defWhiteCol;
  position: relative;


  @include media-breakpoint-up(lg) {
    padding-top: 17px;
    .container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .logout {
    &:hover {
      color: #212529;
    }
  }

  .container {

    .logo {
      padding-top: 0;
      display: inline-block;
      width: 153px;
      img {
        max-width: 100%;
      }
    }

    .phone {
      span {
        color: #32d14d;
        font: {
          family: $font-rbt;
          weight: bold;
          size: 23px;
        }
        letter-spacing: .6px;
      }
    }

    .header__left {
      margin-left: auto;

      .cart-widget-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .cart-link {
          a {
            margin-bottom: 1px;
          }
        }
      }

      &__top {
        display: flex;
        justify-content: flex-start;
        padding: 0 24px;

        &__item {
          margin-right: 5px;
          display: inline-block;

          &:last-child {
            margin-right: 0;
            align-self: center;
            @include media-breakpoint-down(md) {
              margin-right: 10px;
              height: 100%;
              justify-content: flex-end !important
            }
          }

          .btn {
            font-size: 14px;
            line-height: 24px;

            &.call {
              padding: 3px 20px 3px 10px;
            }

            &.login {
              padding-right: 22px;

              .glyphicon {
                font-size: 11px;
                margin-right: 2px;
              }

              &:hover {
                .glyphicon {
                  color: $defBlackCol;
                }
              }

            }
          }

          & + .header__left__top__item {
            margin-left: 12px;

            .cart-link i {
              cursor: pointer;
              vertical-align: middle;
            }
          }

          &.login {
            padding-left: 3px;
          }

          &.signup {
            padding-top: 7px;
            margin-left: 7px;

            .dotted {
              margin-left: 10px;
            }
          }
        }
      }

      &__menu {
        position: relative;
        display: inline-block;

        width: 100%;

        ul {
          display: flex;
          padding: 0;
          list-style: none;
          margin-bottom: 0;

          li {
            position: relative;
            flex-basis: auto;
            list-style-type: none;
            flex-grow: 1;
            text-align: center;
            padding: 0;

            &:hover {
              a {
                border-color: $defGreenCol;
              }
            }

            a {
              display: block;
              color: $defWhiteCol;
              text-decoration: none;
              border-bottom: 4px solid transparent;
              padding: 16px 0 13px;
              transition: all 0.3s ease;
              font: {
                family: $font-rbt;
                weight: 400;
                size: 18px;
              }
            }

            &.active {
              //background: $defWhiteCol;
              a {
                //color: $defGreenCol;
                text-decoration: none;
                //border-bottom: none;
                border-color: $defGreenCol;
              }
            }

          }
        }
      }

      &__inner {
        margin-left: auto;
        padding: 10px 24px 0 24px;
      }
    }
  }

}

#cart-widget {
  margin-left: auto;
}

.cart-widget--header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.cart-mini {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 200;
  padding: 5px 15px;
  min-width: 300px;
  max-width: 300px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
  border-radius: 5px;
  margin-top: 8px;

  p {
    margin-bottom: 0;
    color: #333;
    text-align: center;
  }

  &__item {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #f4f4f4;
  }

  &__thumbnail {
    position: relative;
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    overflow: hidden;
  }

  &__list {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }

  &__info {
    margin-left: 50px;
    color: #333;
    margin-right: 20px;
    height: 50px;
    padding-top: 5px;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: $defBlueCol;

      &:hover {
        color: #18568f;
      }
    }
  }

  &__base-price {
    display: flex;
    font-size: 12px;
  }

  &__delete-btn {
    position: absolute;
    right: 0;
    top: calc(50% - 7px);
    vertical-align: middle;
    display: block;
    width: 15px;
    height: 15px;
    color: #b8b8b8;
    line-height: 15px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #999;
    }
  }

  &__sum {
    color: #333;
    text-align: right;
  }

  &__bottom {
    text-align: center;
    padding: 15px 0 10px;

    a {
      &:hover {
        color: white;
      }
    }
  }
}