.modal{
  &--xs{
    .modal-dialog{
      max-width: 350px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 10px;
    }
  }

  &--sm{
    .modal-dialog{
      max-width: 440px;
      margin-right: auto;
      margin-left: auto;
      padding: 0 10px;
    }
  }

  &-header{
    padding: 15px 15px 0 15px !important;
    border-bottom: none !important;
  }
  &-title{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  form{
    .custom-checkbox{
      text-align: center;
      input{
        display: inline-block;
      }
      label{
        display: inline-block;
        margin-left: 10px;
        line-height: 1;
      }
    }
  }
  .recovery-password{
    width: 100%;
    text-align: right;
    a{
      color: #1e69b0;
    }
  }
  .modal-footer{
    @include media-breakpoint-down(xs){
      flex-direction: column;
      .btn{
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
  }
}