
$defWhiteCol: #fff;
$defGreenCol: #32d14d;
$defGrayDarkCol: #666;
$defGrayCol: #818181;
$defGrayCol1: #999999;
$defGrayCol2: #acb7c1;
$defBlackCol: #333333;
$defBlackCol1: #333232;
$defBlueDarkCol: #336699;
$defBlueCol: #1e69b0;
$defBlueCol0: #218fc1;
$defBlueCol1: #a4d3ff;
$defBlueCol2: rgb(243,249,252);
$defBlueCol3: #d9e5ef;
$defBlueCol4: #f6faff;

$colors: (
        "blue": $defBlueCol,
        "indigo": #6610f2,
        "purple": #6f42c1,
        "pink": #e83e8c,
        "red": #dc3545,
        "orange": #fd7e14,
        "yellow": #ffc107,
        "green": $defGreenCol,
        "teal": #20c997,
        "cyan": #17a2b8,
        "white": $defWhiteCol,
        "gray": $defGrayCol,
        "gray-dark": $defBlackCol
) !default;

$primary : $defBlueCol !default;
$secondary : darken($defBlueCol,20%)  !default;
$success : $defGreenCol  !default;
$info : $defBlueCol1  !default;
//$warning :
//$danger :
//$light :
$dark : $defBlackCol  !default;

$blue: $defBlueCol;

:root {
  --blue: $defBlueCol;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: $defGreenCol;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $defWhiteCol;
  --gray: $defGrayCol;
  --gray-dark: $defBlackCol;
  --primary: $defBlueCol;
  --secondary: $defBlueDarkCol;
  --success: #28a745;
  --info: $defBlueCol1;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: $defWhiteCol;
  --dark: $defBlackCol;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1180px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

$form-border-radius: 30px;


$link-color: $defWhiteCol;
$grid-columns: 12;

$container-max-widths: 1180px;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1180px,
        xl: 1140px
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1180px,
        xl: 1200px
) !default;

$enable-rounded: 1;
$input-height: 30px;
//$font-size-base:14px;
$input-border-radius: 30px;
$s2bs-input-border-radius: 30px;
$border-radius: 30px;
$custom-select-padding-y:           .375rem !default;
$custom-select-padding-x: 1.25rem !default;

$link-color: $primary;
