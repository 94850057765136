.personal {
  .personal-tabs {
    .tab-list-container {
      display: flex;

      margin-bottom: 50px;
      @include media-breakpoint-up(lg) {

        .nav-tabs {
          .nav-item:first-child {
            .nav-link {
              border-radius: 30px 0 0 30px;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 5px;
        flex-direction: column;
        .manager {
          order: 0;
          background: transparent;

          .inner {

            margin: auto;

            .text-block {
              .contact {
                display: block;

                img.icon {
                  height: 18px;
                  width: auto;
                  margin-left: 0;
                }
              }
            }
          }
        }

        .nav-tabs {
          flex-direction: column;
          border-radius: $form-border-radius;

          .nav-item {
            .nav-link {
              border: none;
              padding-right: auto;
              padding-left: auto;
              //&.active{
              //  background: inherit;
              //}
            }
          }

          //i,svg{
          //  height: 24px;
          //  width: 24px;
          //}
          //i{
          //  font-size: 21px;
          //}
        }
      }

      .manager {
        order: 1;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        flex: 1;

        background: $defBlueCol2;
        border-radius: 0 30px 30px 0;

        .inner {

          display: flex;
          flex-wrap: nowrap;

          .text-block {
            vertical-align: middle;
            color: $defBlackCol;
            margin-left: 20px;
            display: inline-block;

            img.icon {
              margin: 0 10px 0 0;
            }
          }
        }

      }

      .nav-tabs {
        border-bottom: none;
        background: transparent;

        @include media-breakpoint-down(md) {
          padding-top: 20px;
        }

        .nav-item {

          @include media-breakpoint-down(md) {
            &:first-child {
              .nav-link {
                border-radius: 30px 30px 0 0;
                padding-top: 30px;
              }
            }
            &:last-child {
              .nav-link {
                border-radius: 0 0 30px 30px;
                padding-bottom: 30px;
                max-height: 70px;
              }
            }
          }

          .nav-link {
            padding: 32.5px 40px;
            color: $defBlueCol;
            background: $defBlueCol4;
            border: none;

            border-radius: 0;

            @include media-breakpoint-down(md) {
              padding: 8px 0 8px calc(50% - 101px);
            }

            span.caption {
              @include font-16();
              @extend .dotted;
              color: $defBlueCol;
              vertical-align: bottom;
              margin-left: 15px;
            }

            .cart-btn {
              color: $defWhiteCol;
              font-size: 12px;
              line-height: 10px;
              padding: 3px 6px;
              margin-left: 15px;
            }

            &.active {
              color: $defBlackCol;

              span.caption {
                color: $defBlackCol;
                border-bottom: none;
                font-weight: 800;
              }
            }

            img.icon {
              height: 25px;
              width: auto;

              &.cart {
                filter: invert(100%);
              }
            }
          }
        }
      }
    }
  }

  .tab-content {

    .personal-table {
      @include font-14();
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .personal-row {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;

        border-top: $defBlueCol1 1px solid;
        border-bottom: $defBlueCol1 1px solid;

        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;

        > .img-wrapper {
          flex: 0 0 40px;

          @include media-breakpoint-down(md) {
            align-self: flex-start;
          }
        }


        > .close {
          @include media-breakpoint-down(md) {
            align-self: flex-start;
          }
        }

        div.personal-col:first-of-type {
          //width: 40%;
        }

        .personal-col {
          margin-right: 25px;
          margin-left: 25px;
          text-align: center;
          //vertical-align: middle;
          border-top: none;
          border-bottom: none;
          flex: 1 0 auto;
          width: 25%;


          @include media-breakpoint-down(md) {
            margin-right: 10px;
            margin-left: 10px;
          }

          a {
            color: $defBlueCol;
            text-decoration: underline solid;
          }

          img {
            max-width: 35px;
            max-height: 65px;
          }

        }
      }


      .personal-thead {
        @extend .mobile-hidden;
        font-weight: 800;
        border-top: none;
        border-bottom: $defBlueCol 1px solid;

        + .personal-row {
          border: none;
        }
      }

      td {
        border-top: $defBlueCol1 1px solid;
        border-bottom: $defBlueCol1 1px solid;
        margin-right: 25px;
        margin-left: 25px;
        text-align: center;

        vertical-align: middle;

        a {
          color: $defBlueCol;
          text-decoration: underline solid;
        }

        img {
          max-width: 35px;
          max-height: 65px;
        }
      }
    }


    #personal-orders {
      padding-bottom: 80px;

      .swiper-slide {
        @media (max-width: 1200px) and (min-width: 768px) {
          width: 100% !important;
        }
      }

      .personal-table {
        .personal-thead {
          border-bottom: #1e69b0 1px solid !important;
        }

        .personal-row {
          align-items: flex-start;
          border-bottom: none;

          .personal-col {
            margin-left: 10px;
            margin-right: 10px;

            &:nth-child(3) {
              flex: 2 2 100%;
              text-align: left;
            }

            &:last-child {
              flex: 0 0 90px;

              a {
                text-decoration: none;
              }
            }

            .product-list-opener-js {
              color: #212529;

              span {
                margin-right: 5px;
              }

              i {
                vertical-align: middle;
                transition: all .3s ease;
                transform: rotate(0deg);
              }
            }

            .product-list-header {
              display: flex;
              flex: 1 1 auto;
              justify-content: flex-end;

              & > * {
                margin-left: 10px;
                margin-right: 10px;
                @include media-breakpoint-down(sm) {
                  margin-left: 5px;
                  margin-right: 5px;
                }

                &:first-child {
                  flex: 1 1 auto;
                  text-align: left;
                  margin-left: 0;
                }

                &:not(:first-child) {
                  flex: 0 0 60px;
                  text-align: center;
                  @include media-breakpoint-down(sm) {
                    flex: 0 0 80px !important;
                  }
                  @include media-breakpoint-down(md) {
                    flex: 0 0 120px;
                  }
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .product-list {
              list-style-type: none;
              padding: 0;
              margin: 0;
              padding-top: 5px;

              &__item {
                display: flex;
                flex: 1 1 auto;
                justify-content: flex-end;
                @include media-breakpoint-down(md) {
                  margin-bottom: 10px;
                }

                & > * {
                  margin-left: 10px;
                  margin-right: 10px;

                  &:first-child {
                    flex: 1 1 auto;
                    text-align: left;
                    margin-left: 0;
                  }

                  &:not(:first-child) {
                    flex: 0 0 60px;
                    text-align: center;
                    @include media-breakpoint-down(md) {
                      flex: 0 0 120px;
                    }
                    @include media-breakpoint-down(sm) {
                      display: none;
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            justify-content: left;
            padding: 0;

            &:nth-child(2) {
              //border-top-left-radius: $input-border-radius;
              //border-top-right-radius: $input-border-radius;
            }
            &:hover {
              background: $defBlueCol4;
            }
            .personal-col {
              margin: 0;
              flex: 0 0 50%;
              padding: 20px 15px;

              &:nth-child(3) {
                @include media-breakpoint-between(sm, md) {
                  text-align: center;
                }
              }

              &:last-child {
                flex: 0 0 auto;
              }

              .text-wrapper {
                text-align: left;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            &.personal-thead {
              > :nth-child(1) {
                flex: 0 0 65px;
              }

              > :nth-child(2) {
                flex: 0 0 62px;
              }

              > :nth-child(3) {
                text-align: left;
                flex: 1 1 443px !important;
              }
            }
            .personal-col {
              flex: 0 0 60px;

              //&:nth-child(3){
              //
              //}
            }
          }
        }
      }

      .swiper {
        .swiper-wrapper {
          padding-bottom: 46px;
        }

        @include media-breakpoint-down(sm) {
          .promo-list {
            .promo-item {

              flex-direction: column;

              img {
                border-radius: $form-border-radius;
                margin-bottom: 30px;
                min-height: 90px;
                width: 102px;
                flex: 0 0 90px;
              }

              .text-wrapper {
                background: transparent;
                color: $defBlackCol;

                h3 {
                  color: $primary;
                }
              }
            }
          }
        }
      }

      .promo-list {
        padding-bottom: 20px;


      }

      .bottom-btn {
        margin-top: 20px;
      }
    }

    #personal-cart {
      .cart {
        .personal-table {
          .personal-row {
            &.personal-thead {
              > :nth-child(1) {
                //width: 40px;
                flex: 0 0 40px;
              }

              > :nth-child(2) {
                flex: 1 1 400px;
                text-align: left;
              }

              > :nth-child(3) {
                flex: 0 0 50px;
              }

              > :nth-child(4) {
                flex: 0 0 145px;
                min-width: 145px;
              }

              > :nth-child(5) {
                flex: 0 0 55px;
                min-width: 45px;
              }

              > :nth-child(6) {
                flex: 0 0 100px;
                min-width: 100px;
              }
            }

            > .content {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: row;
              align-items: center;
              width: 75%;

              @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                flex-direction: row;
                .personal-col {
                  flex: 1 1 100% !important;

                  margin-right: 10px;
                  margin-left: 10px;

                  + .personal-col {
                    margin-top: 20px;
                  }

                  .text-wrapper {
                    text-align: left;
                    padding-right: 20px;

                    .product-count {
                      justify-content: left;
                    }
                  }
                }
                .close {
                  position: absolute;
                  right: 0;
                  top: 8px;
                  @media (max-width: 450px) {
                    top: -10px;
                    right: -60px;
                  }
                }
              }

              .personal-col {
                &:nth-child(1) {
                  text-align: left;

                  @include media-breakpoint-between(sm, md) {
                    text-align: center;
                  }
                  //flex: 0 0 460px;
                }

                &:nth-child(2) {
                  flex: 0 0 50px;
                }

                &:nth-child(3) {
                  flex: 0 0 145px;
                  min-width: 145px;
                }

                &:nth-child(4) {
                  flex: 0 0 55px;
                  min-width: 45px;
                }
              }
            }
          }
        }

        .bottom {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 30px 0;

          .promo {
            padding: 30px 15px;

            h3 {
              margin-right: 20px;
              margin-left: 20px;
              text-align: center;
            }
          }

          @include media-breakpoint-up(lg) {
            .promo {
              position: relative;

              &:before, &:after {
                content: "";
                height: 100%;
                width: 10px;
                background: url('../img/personal-promo-edge.jpg') no-repeat;
                top: 0;
                right: -5px;
                position: absolute;
                display: block;
                z-index: 2;
              }

              &:after {
                left: -5px;
                transform: rotate(180deg);
              }
            }
          }

          @include media-breakpoint-down(md) {

            margin-right: -15px;
            margin-left: -15px;
            margin-top: -1px;
          }

          .promo {
            display: flex;
            flex-wrap: wrap;
            background: $success;
            padding: 30px 15px;
            flex: 0 1 630px;

            h3 {
              margin-right: 20px;
              margin-left: 20px;
              text-align: center;
            }

            @include media-breakpoint-down(lg) {
              .row {
                &:last-child {
                  .col:first-child {
                    flex: 1 1 385px;
                  }
                }
              }


              //@include media-breakpoint-down(){
              //
              //
              //}
              @include media-breakpoint-down(md) {

                margin-bottom: 30px;
                flex: 0 0 100%;
                .row {
                  flex-wrap: wrap;

                  &:last-child {
                    .col {
                      margin: 10px 0;
                      text-align: center !important;
                    }
                  }
                }
              }
            }

            .row {
              flex-wrap: nowrap;

              &:last-child {
                display: flex;
                justify-content: space-between;

                .col:first-child {
                  flex: 0 0 385px;
                }

                .col:last-child {

                  text-align: end;
                }
              }
            }

            //
            //  flex-basis: 380px;
          }

          .price {
            margin: auto;

            table {
              tr {
                td {
                  &:first-child {
                    padding-right: 70px;
                  }
                }

                .total {
                  font-size: 18px;
                }
              }
            }

          }
        }

        .bottom-btn-container {
          margin: 30px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;


          @include media-breakpoint-down(sm) {

            //flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            .btn-container-item {
              &:last-child {
                margin-bottom: 20px;
                order: -1;
              }
            }
          }
        }
      }

      .ordering {
        margin-bottom: 50px;

        form {
          h3 {
            margin-bottom: 40px;
          }

          .form-group {
            margin: 20px 0;

            .form-control {
              text-align: center;
            }
          }
        }

        .shipment-container {
          .shipment {
            h3 {
              text-align: center;
            }

            background: $defBlueCol4;
            padding: 40px;
            border-radius: $input-border-radius;

            @include media-breakpoint-down(md) {
              background: transparent;
              padding: 30px 0;
            }
          }
        }

        .order {
          padding: 40px;
          @include media-breakpoint-down(sm) {

            padding: 0 15px 0 15px;
            h3 {
              text-align: center;
              margin: auto;
              width: 100%;

              + a {
                margin: 20px auto;
              }
            }
          }

          h3 {
            + a {
              margin-left: 35px;
              color: $primary;
            }
          }

          .personal-table {

            @include media-breakpoint-down(md) {
              .personal-row {
                .personal-col {
                  + .personal-col {
                    flex: 0 0 auto;
                  }

                  &:nth-child(2) {
                    flex: 0 0 auto;
                  }
                }
              }
            }
            @include media-breakpoint-down(sm) {

              .personal-row {
                flex-wrap: wrap;
                justify-content: space-around;

                padding-top: 20px;
                padding-bottom: 20px;

                .personal-col {

                  flex: 0 0 50%;
                  text-align: left;

                  &:first-child {
                    flex-basis: 100%;
                  }

                  + .personal-col {
                    margin: 20px 0 0 0;
                    padding-left: 10px;
                  }

                  &:nth-child(2) {
                    flex: 0 0 50%;
                    margin: 20px 0 0 0;
                    padding-left: 10px;
                    text-align: left;
                  }
                }
              }
            }

            .personal-row {
              &:first-child {
                border-top-color: $primary;
              }

              .personal-col {
                &:first-child {
                  text-align: left;
                  flex: 1 1 100%;
                }

                &:nth-child(2) {
                  text-align: center;
                  flex: 0 0 30px;
                }
              }
            }
          }

          .price {
            flex: 1 1 100%;
            @include clearfix();
            margin: 40px 20px 40px auto;

            .text-block {
              float: right;

              table {
                tbody {
                  tr {
                    :last-child {
                      padding-left: 65px;
                    }
                  }
                }
              }
            }
          }

          button {
            float: right;

            @include media-breakpoint-down(sm) {

              margin: auto;
              display: block;
              float: none;
            }
          }
        }
      }

      .price {
        .text-block {
          table {
            .total {
              font-size: 18px;
            }
          }
        }
      }

    }
  }

  .paginator {
    margin: 30px auto;
  }

  #personal-cart {
    .personal-table {

    }
  }

  #personal-profile {
    padding-bottom: 50px;

    form {

      h3 {
        margin-bottom: 40px;
      }

      .form-control {
        margin: 0;
        text-align: center;
        //.form-check{
        //  margin-bottom: 40px;
        //}
      }

      .form-check {
        padding-right: 30px;
        padding-left: 30px;

        .form-check-label {
          text-align: left;
        }
      }

      select, option {
        text-align: center;
      }
    }

    .address-container {
      order: 1;

      .address {
        background: $defBlueCol4;
        padding: 40px;
        border-radius: $form-border-radius;

        .more {
          margin-bottom: 15px;
        }

        &-list {
          padding: 0;
          margin: 0;
          list-style: none;

          h4 {
            margin-bottom: 10px;
          }

          &__item {
            display: flex;
            align-items: center;

            & > span {
              flex: 1 1 auto;
              padding-right: 10px;
              text-align: left;
            }

            .actions {
              flex: 0 0 40px;
              color: #1e69b0;

              span {
                cursor: pointer;
              }
            }
          }
        }
      }

      .more {
        color: $primary;
        text-align: center;
        cursor: pointer;
      }
    }

    .data {
      padding-top: 40px;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
      h3 {
        text-align: center;
      }
      .data {
        padding-top: 0;
      }
      .address-container {
        padding-left: 15px;
        padding-right: 15px;

        order: -1;

        .address {
          padding-left: 0;
          padding-right: 0;
          background-color: transparent;
        }
      }
    }
  }


  .profile {
    @include make-row();
    align-items: flex-start;
    margin-bottom: 60px;

    h3 {
      margin-bottom: 40px;
    }

    &__data {
      padding: 40px 55px;
      @include media-breakpoint-down(xs) {
        padding: 40px 15px;
      }

      .centered-btn-container {
        text-align: center;
        margin-top: 30px;
      }
    }

    &__address {
      padding: 0 40px;
      @include media-breakpoint-down(xs) {
        padding: 0 15px;
      }

      .address-container {
        padding: 40px 15px;
        background: #f6faff;
        border-radius: 30px;
      }

      h3 {
        text-align: center;
      }
    }
  }
}