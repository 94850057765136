
.footer {
  //margin-right: -15px; //
  //margin-left: -15px;
  background: $defBlueCol;
  color: $defWhiteCol;
  position: relative;
  //height: 230px;
  padding-top: 43px;

  @include media-breakpoint-up(lg) {
    .text-small {
      line-height: 20px;
    }
    .container {
      max-width: 1122px;
    }
    .line {
      width: 100%;
      display: flex;
      flex-direction: row;

      &.bottom {
        margin-top: 25px;
        align-items: flex-end;

        .col {
          padding: 0;
        }

        .dev {
          text-align: center;
          padding: 0;

          .text-small {
            padding-left: 3px;
            padding-right: 0;
          }
        }
      }

      .footer-col {
        .logo {
          margin-top: 5px;
          margin-right: 89px;

          img {
            width: 164px;
          }
        }

        ul.link-list {
          flex-direction: column;
          margin-right: 50px;
          max-width: 200px;

          .list-item {
            margin-bottom: 10px;
          }
        }

        &.contact {
          padding-left: 52px;
          margin-top: -3px;
          width: 280px;

          .phone {
            letter-spacing: .0px;
            margin-bottom: 5px;
          }
        }

      }
    }
  }

  .logo {

    margin-top: 8px;
    margin-right: 74px;

    img {
      width: 162px;
    }
  }

  &-col {
    //height: 100%;
    display: inline-block;

  }

  .line {
    width: 100%;

    //@include make-row();
    display: flex;
    flex-direction: row;

    &:first-child {
      //padding-top: 43px;

      > :last-child {
        padding-left: 88px;
      }
    }

    //&.bottom.mobile-hidden{
    //
    //  margin-top: 35px;
    //  .col{
    //    margin: 0;
    //    padding: 0;
    //  }
    //  .dev{
    //    text-align: right;
    //    padding-right: 30px;
    //    >.text-small{
    //      padding-right: 56px;
    //    }
    //  }
    //}


  }

  ul.link-list {

    //margin-right: 146px;
    li {

      margin-bottom: 6px;
      list-style-type: none;

      a {
        @include font-b-14();
      }
    }

  }

  .phone {
    min-width: 156px;
    font: {
      family: $font-rbt;
      weight: 400;
      size: 24px;
    }
    letter-spacing: 0.6px;
  }

  .icons {
    text-align: center;

    a {
      + a {
        margin-left: 35px;

        img {
        }
      }
    }

  }

  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 30px;


    .logo {
      display: none;
    }

    .line {
      padding: 0 !important;
      margin: 0;
      justify-content: space-around;

      ul.link-list {
        display: inline-block;
        margin: 0;
        padding: 0;
      }

      @include media-breakpoint-down(sm) {

        margin-left: 0;
        padding: 0 0 0 calc(50% - 97px) !important;
        flex-direction: column;
        //justify-content: center;
        .footer-col {
          padding: 0 !important;
          //  &:nth-child(2){
          //    flex: 1 1 43%;
          //  }
          //  &:nth-child(3){
          //    flex: 1 1 29%;
          //  }
          //  &:nth-child(4){
          //    flex: 1 1 13%;
          //  }
        }
      }

      &:last-child {
        margin-top: 15px;
      }

      ul {
        margin: 0 40px 0 0;
        display: block;
      }
    }
    &-col {
      flex: 0 0 auto;
      display: inline-block;
      padding: 0 30px 0 30px !important;

      &.contact {
        //margin: auto;
        //text-align: center;
        //padding-left: 0 !important;
      }

      &:first-child {
        display: none;
        flex: 0;
      }
    }
    .phone {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .bottom {
      text-align: center;
      display: block;
      padding-top: 20px;
      padding-bottom: 1px;

      .icons {
        padding-bottom: 20px;
      }
    }

  }
}

