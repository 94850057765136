.p-home{

  .home__top-swiper {
    color: $defWhiteCol !important;
    &__item {

      color: $defWhiteCol !important;

      position: relative;
      //height: 536px;
      width: 100%;
      .img {
        img {
          width: 100%;
          //max-height: 536px;
          object-fit: fill;
        }
      }
      &__inner {
        color: $defWhiteCol !important;

        top: 20px;
        position: absolute;
        z-index: 2;

        font: {
          family: $font-rbt;
          weight: 400;
          size: 12px;
        }
      ;
        //padding: 170px 0 0 54px;
      }
      &-header {
        @extend h1;
        color: $defWhiteCol !important;
        //line-height: 1;
        //margin-bottom: 25px;

        padding-top: 130px;
        padding-left: 50%;
      }
      &-caption {
        color: $defWhiteCol !important;
        width: 75%;
        font-size: 18px;
      }
    }
  }

  .product{
    margin-top: 90px;
    margin-bottom: 80px;
    &__title{
      color: $defBlackCol;
      background: url("../img/waterTitleLine.png") no-repeat 50% 58px;
      padding-bottom: 80px;
      margin-bottom: 0;
    }
    &-btn{
      @extend .btn;
      @extend .btn-primary;
      @extend .btn-big;

      margin-top: 5px;
    }
  }

  .equipment{
    background: url('../img/waterDropBack.jpg');
    //background-size: 112%;
    width: 100%;
    //height: 490px;
    border-radius: 40px;
    padding-top: 60px;
    padding-bottom: 30px;

    &-list{
      padding-bottom: 30px;
    }
    &-caption{
      display: block;
    }
  }

  .feature{
    margin-top: 80px;
    margin-bottom: 70px;

    .photo{
      width: 100%;
      height: 384px;
      background: url('../../pictures/home/advantagesBack.jpg');
      position: relative;
      margin-bottom: 140px;

      .text{
        background: $defGreenCol;
        color: $defWhiteCol;
        border-radius: 30px;
        width: 530px;
        padding: 70px 30px;
        right: 90px;
        top: 70px;
        position: absolute;
        @include font-18;

        @include media-breakpoint-down(md){
          padding: 40px 20px;
        }
      }
    }
  }

  .review{
    color: $defGrayDarkCol;
    margin-top: 60px;
    margin-bottom: 80px;

    .review-list{
      margin-bottom: 50px;
    }
  }
}
