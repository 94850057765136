
%product-list-inner-hover {

  background: $defBlueCol2;
  text-decoration: none;
  border-radius: 0 0 30px 30px;

  .new-price {
    color: $defWhiteCol;

    .new-price__price, .new-price__title {
      color: $defWhiteCol;
    }
  }


  .item-price {
    background-color: $defGreenCol;
    color: $defWhiteCol;

    i {
      display: none;
      opacity: 0;
    }

    .item-sale__is-available {
      color: white;
      opacity: 1;
    }

    i + div {
      display: block;
      opacity: 1;
    }

    hr {
      color: $defWhiteCol;
      background: $defWhiteCol;
    }
  }

  .item-text {
    .item-title {
      color: $defGreenCol;
    }
  }
}

.product-list {

  @include make-row();
  padding-bottom: 20px;
  @include media-breakpoint-down(xs) {
    justify-content: center;
  }

  .product-item {
    @include make-col-ready();
    @include make-col(3, 12);

    text-decoration: none;
    min-width: 280px;
    text-align: center;

    .product-inner {
      display: inline-block;
      padding: 20px;
      margin: auto;
      height: 100%;
      width: 100%;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }

      .img-wrapper {
        position: relative;
        display: inline-block;
        //padding-right: 70px;
        //padding-bottom: 20px;
        height: 240px;
        line-height: 240px;

        img {
          max-width: 150px;
          width: auto;
          max-height: 100%;
        }
      }

      .item-sale {
        @extend .btn;
        position: absolute;
        background-color: red;

        color: white;
        width: 110px;
        z-index: 2;
        left: -20%;
        top: -20%;

        span {
          display: block;
          margin: 5% 0;
        }

        &__is-available {
          min-height: 20px;
          margin: 3px 0;
          color: $green;

          &.not {
            font-size: 0.8rem;
            color: red;
          }
        }

        .item-sale__sale {
          font-size: 22px;
        }

        .item-sale__old-price {
          font-size: 17px;
          text-decoration: line-through;
        }

        .fas {
          font-size: 1.75rem;
        }

        i {
          display: block;
          opacity: 1;
          color: white;
          transition: opacity .3s ease;
        }

        i + div {
          display: none;
          opacity: 0;
          transition: opacity .3s ease;
        }
      }

      .item-price {
        @extend .btn;
        position: absolute;
        background-color: $defBlueCol2;

        color: $defBlackCol;
        //width: 100px;
        //height: 120px;
        z-index: 2;
        right: 0;
        bottom: 0;

        span {
          position: relative;

          display: block;
          //margin-top: 15px;
          line-height: 28px;
          font: {
            size: 22px;
            family: $font-rbt;
            weight: 400;
          };
        }

        .new-price {
          color: red;
          padding: 0;
          margin: 0;

          span {
            line-height: 1;
            padding: 0;
            margin: 5% 0;
            font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
          }

          .new-price__title {
            font-size: 10px;
            text-transform: uppercase;
            margin: 10% 0;
          }
        }

        .fas {
          font-size: 1.75rem;
        }

        hr {
          color: $defGrayCol2;
          background: $defGrayCol2;
          padding: 0;
          margin: 0;
        }

        i {
          display: block;
          opacity: 1;
          color: $defBlackCol;
          transition: opacity .3s ease;
        }

        i + div {
          display: none;
          opacity: 0;
          transition: opacity .3s ease;
        }
      }

      .item-text {
        padding-top: 20px;

        .item-title {

          color: $defBlueCol;
          font: {
            family: $font-rbt;
            size: 18px;
            weight: 800;
          };
          text-align: left;
          line-height: 1.5em;
          text-overflow: ellipsis;
          height: 61.5px;
          overflow: hidden;
        }

        .item-caption {
          color: $defGrayCol;
          font: {
            family: $font-rbt;
            size: 14px;
            weight: 400;
          };
          text-align: left;
          line-height: 1.5em;
          height: 4.5em;
          overflow: hidden;

          p {
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &:hover {
        @extend %product-list-inner-hover;
      }
    }
  }
}

.swiper-slide {
  .product-list {

    padding-bottom: 50px;

    a {
      text-decoration: none;
    }

    .product-item {
      .product-inner:hover {
        @extend %product-list-inner-hover;
      }
    }
  }
}


.equipment-list {
  @include make-row();

  margin-right: 0;
  margin-left: 0;

  .equipment-item {
    @include make-col-ready();
    @include make-col(3, 12);
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    @include setLinkColor($defWhiteCol);

    .img-wrapper {
      position: relative;
      display: inline-block;
    }

    .wrapper {
      display: block;

      .equipment-caption {
        margin-top: 30px;
        @include font-b-18();
        @extend .dotted;
      }
    }

  }
}

.feature-list {
  @include make-row();
  color: $defBlackCol;

  .feature-item {
    @include make-col-ready();
    @include make-col(3, 12);
    padding-bottom: 20px;
    padding-left: 30px;

    &._one {
      @include make-col-ready();
      @include make-col(12, 12);

      text-align: center;

      h3 {
        text-align: center;
      }
    }

    .img-wrapper {
      position: relative;
      display: block;

      img {
        max-width: 100%;
        height: 42px;
      }
    }

    .feature-caption {
      margin-top: 30px;
    }
  }
}


.review-list {

  @include make-row();
  color: $defGrayDarkCol;

  .review-item {

    //@include make-col-ready();
    //@include make-col(3, 12);
    img {
    }

    .review-title {
      margin-top: 30px;
    }

    .review-caption {
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
    }
  }

  // equal columns place full row width
  &._fw {
    .review-item {
      @extend .col;

      //padding-bottom: 20px;
      //padding-left: 30px;

      img {
        margin-bottom: 15px;
      }

      .review-title {
        margin-top: 30px;
      }

      .review-caption {
        margin-top: 30px;
      }
    }
  }
}

.link-list {
  @include make-row();

  justify-content: space-around;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }

  .link-item {
    a {
      display: inline-block;
      color: $defBlueCol;
      padding: 3px 12px;
      border: 1px solid transparent;
    }

    &:hover {
      a {
        @extend .btn, .btn-primary;
        padding-right: 12px;
        padding-left: 12px;
        color: $defWhiteCol;
      }

    }

    &.active {
      a {
        @extend .btn;
        background-color: #18568f;
        border-color: #174f84;
        padding-right: 12px;
        padding-left: 12px;
        color: $defWhiteCol;
      }
    }
  }
}

@mixin promo-item-sm {
  border-radius: 20px;
  min-width: 300px;
  img {
    height: 90px;
    width: 102px;
    border-radius: 10px;
  }

  &:hover {
    img {
      border-radius: 10px 0 0 10px;
    }
  }
}

.promo-list {
  @include make-row();

  .promo-item {
    @include make-col-ready();
    @include make-col(6, 12);


    display: flex;
    align-items: center;
    //flex: 1 1 450px;
    min-width: 450px;
    margin-bottom: 30px;

    border-radius: 30px;

    color: $defBlackCol;

    h3 {
      color: $defBlueCol;
      @extend .dotted;
    }

    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
    @include media-breakpoint-down(sm) {
      @include promo-item-sm();
    }

    img {
      height: 225px;
      width: 255px;
      border-radius: 20px;
      transition: all .3s ease;
    }

    .text-wrapper {
      display: flex;
      align-items: center;
      border-radius: 0 20px 20px 0;
      width: 100%;
      height: 100%;
      transition: all .3s ease;

      .text-block {
        margin-left: 25px;

      }
    }

    &:hover {
      color: $defWhiteCol;
      text-decoration: none;

      @include media-breakpoint-down(xs) {
        color: $defBlackCol;

        h3 {
          color: $primary;
        }
        img {
          border-radius: 10px;
        }
        .text-wrapper {
          background: transparent;
        }
      }

      h3 {
        text-decoration: none;
        color: $defWhiteCol;
      }

      img {
        border-radius: 20px 0 0 20px;

      }

      .text-wrapper {
        background: $defBlueCol;

        p {
          text-decoration: underline;
        }
      }
    }
  }
}

.other-promo {
  h3 {
    text-align: center;
  }

  .promo-list {

    .promo-item {
      .text-wrapper {
        border-radius: 20px;

        .text-block {
          padding: 20px 0;
        }
      }

      &:hover {
        @include media-breakpoint-down(md) {
          .text-wrapper {
            background: #1e69b0;

            h3 {
              color: white;
            }

            p {
              text-decoration: none;
              color: white;
            }
          }
        }
      }
    }
  }
}

.goods-list_swiper {
  .product-list {
    max-width: 100%;
    @include media-breakpoint-down(xs) {
      margin: 0;
    }

    .product-item {
      min-width: 100%;
      flex: 1;

      .product-inner {
        width: 100%;

        .img-wrapper {
          height: 220px;
          width: 150px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 0 0;
        }

        .item-price {
          right: -50px;
          bottom: -15px;
        }
      }
    }
  }

  &_related {
    .product-list {
      .product-item {
        .product-inner {
          .item-text {
            .item-title {
              height: 54px;
              overflow: hidden;
            }

            .item-caption {
              height: 65px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

#personal-orders {
  .promo-list {
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-down(xs) {
      padding-bottom: 0 !important;
    }

    .promo-item {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0;
      @include media-breakpoint-down(xs) {
        padding-right: 0;
        margin-bottom: 0;
      }

      img {
        @include media-breakpoint-down(xs) {
          margin-bottom: 0 !important;
          height: 200px !important;
          width: 150px !important;
        }
      }
    }
  }
}
