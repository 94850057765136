.subscribe {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  &__side {
    flex: 1 1 50%;

    @media (max-width: 450px) {
      flex: 1 1 100%;
    }

    .text {
      @media (max-width: 450px) {
        text-align: center;
      }
    }

    .envelope {
      @media (min-width: 450px) {
        margin-right: 10px;
      }
    }
  }

  .form-elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .form-group {
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 1125px) {
        width: 100%;
        margin: 5px 0;
      }

      &.captcha-image {
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        a {
          border-radius: 0;
          height: 100%;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          i {
            top: auto;
          }
        }

        img {
          height: 100%;
        }

        @media (max-width: 450px) {
          height: 40px;
        }
      }
    }
  }
}