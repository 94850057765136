.p-equipment, .p-product{
  .link-list{
    margin-bottom: 60px;
  }

  section {
    margin-bottom: 50px;

    &.product-list{
      padding-bottom: 0;
    }
    &:last-child {
      margin-bottom: 80px;
    }
  }
}

.p-delivery{
  padding-bottom: 80px;
  .delivery-contacts{
    margin-bottom: 30px;
    h3 {
      text-align: center;
      @include media-breakpoint-down(xs){
        text-align: left;
      }
    }
    &__item{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      justify-content: center;
      .delivery-city{
        min-width: 200px;
      }
    }
  }
  @include media-breakpoint-down(xs){
    .delivery-contacts{
      &__item{
        flex-direction: column;
      }
    }
  }
}

.p-common{
  padding-bottom: 80px;
}

.p-service{
  @include media-breakpoint-down(md){
    &.container{
      padding-right: 0;
      padding-left: 0;
    }
  }

  section {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 80px;
    }
  }

  .service-row{
    width: 100%;
    display: flex;

    @include media-breakpoint-down(sm){
      flex-wrap: wrap;
    }
    .service__col-left{
      @include make-col-ready();
      //@include make-col(8);

      //flex: 1 1 330px;

    }
    .service__col-right{

      @include make-col-ready();
      //@include make-col(5);

      flex-basis: 440px;
      flex-shrink: 0;
      flex-grow: 0;
      min-width: 440px;

      @include media-breakpoint-down(sm){

        flex-basis: auto;
        min-width: 1px;
        .top{
          margin-top: 20px;
          padding: 15px;
        }

        .exclamation{
          padding: 0 15px;
        }
      }

      .top{
        background: url("../../pictures/home/coolersBack.jpg");
        border-radius: 30px;
        padding: 35px;
        color: $defWhiteCol;

        .top-title{
          padding-bottom: 20px;
          img{
            float: left;
            margin-right: 20px;
          }
        }
      }
      .exclamation{
        display: flex;
        .img-wrapper{
          margin-right: 20px;
        }
        padding: 0 25px 25px;
      }
    }
  }
}

.promo{
  padding-bottom: 90px;
}

.p-promo-item{
  padding-bottom:90px;
  .swiper{
    margin-bottom:40px;
    .img-wrapper{
      margin: 0 auto 50px;
      width: 535px;
      height: 315px;
      display: block;
      text-align: center;
      img{
        border-radius: $form-border-radius;
        max-width: 100%;
        max-height: 100%;
      }
      @include media-breakpoint-up(xs){
        width: 100%;
        height: auto;
      }
    }
  }
  section.text-block{
    margin-bottom: 90px;
    @include media-breakpoint-down(xs){
      margin-bottom: 40px;
    }
  }

  .swiper.other-promo{
    margin-bottom: 70px;
    .swiper-wrapper{
      .promo-list{
        flex-wrap: nowrap;
        .promo-item{
          @include promo-item-sm;
          flex-basis: 50%;
        }


        @include media-breakpoint-up(lg){
          padding-right: 80px;
          padding-left: 80px;

          .promo-item{
            &:hover{
              .text-wrapper{
                background: transparent;
                color: $defBlackCol;
                h3{
                  color: $primary;
                }
              }
            }
            img{
              display: none;
            }
          }
        }
        @include media-breakpoint-down(md){
          .promo-item{
            .text-wrapper{
              .text-block{
                >:not(.item-title){
                  display: none;
                }
                .item-title{
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        @include media-breakpoint-down(xs){
          min-width: 1px;
          .promo-item{
            flex-basis: 100%;
            &:hover{
              .text-wrapper{
                background: $primary;
                color: white;
                .item-title{
                  color: white;
                }
              }
            }
          }
          //img{
          //  display: none;
          //}
        }

      }
    }

  }


}


.contacts{
  padding-bottom: 90px;
  .map-container {
    position: relative;

    .dropdown{

      margin-top: 30px;
      margin-left: 80px;

      @include media-breakpoint-down(md){
        margin-top: 0;
        margin-left: 0;
        .list-group{
          margin-top: 35px;
        }

      }
      .btn.dropdown-toggle{

      }

    }
    ul.list-group {
      position: absolute;
      z-index: 3;
      background: transparent;
      padding: 0;
      top: 0;
      margin-top: 30px;
      margin-left: 80px;
      border-radius: 20px;

      direction: rtl;

      max-height: 440px;
      overflow-y: scroll;

      @include media-breakpoint-down(md){

        margin-top: 45px;
        margin-left: 10px;
        max-height: 455px;
        overflow-x: hidden;
      }

      >:first-child li{
        border-radius: 20px 20px 0 0;
      }
      >:not(first-child) li{
        border-top: none;
      }
      >:not(last-child) li{
        border-bottom: none;
      }
      >:last-child li{
        border-radius: 0 0 20px 20px;
      }

      .list-group-item {
        cursor: pointer;
        background: $defBlueCol;
        border-radius: 0;
        h3 {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $defBlueCol0;
        }

      }
    }

  }
  .bottom-text{
    padding-top: 50px;
    height: 300px;
    margin-bottom: 120px;
    position: relative;
    width: 100%;
    @include make-row();
    .text-block{
      @include make-col-ready();
      //@include make-col-offset(3);
      margin-left: 400px;
      //@include make-col(4.2);
      flex: auto;
      padding-right: 493px;

    }
    .feedback{
      //margin-top: -50px;
      z-index: 2;
      position: absolute;
      right: 90px;
      top: -50px;
      margin-top: 0;
    }

    @include media-breakpoint-down(md) {
      @include make-container();

      margin-bottom: 50px;

      padding-top: 30px;
      height: auto;
      display: flex;
      flex-wrap: nowrap;
      .text-block {
        width: auto;
        margin-left: 0;
        flex: 1 1 100%;
        padding-right: 15px;
      }

      .feedback {
        position: relative;
        z-index: 1;
        right: auto;
        top: auto;
        margin-top: 0;

        flex: 0 0 365px;
      }

      @include media-breakpoint-down(sm){
        .text-block {
          margin-bottom: 30px;
        }
        flex-wrap: wrap;
        .feedback {
          margin: auto;
          flex-basis: 100%;
        }
      }
    }
  }
}

.not-found{
  position: relative;
  .img{
    width: 100%;
    height: 65vw;
    background: url("../img/404Back.jpg") center;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%; /* Современные браузеры */
  }
  &-middle{
    position: absolute;
    z-index: 2;
    bottom: 13vw;
    top: unset;
    width: 100%;
    text-align: center;
    padding-right: 33%;
    padding-left: 33%;
    h3{
      font-size: 18px;
      margin: 0 auto 30px auto;
      display: block;
      color: $defWhiteCol;
      text-shadow: 2px 2px 6px black;
      text-align: center;
      font-weight: bold;
    }
    .btn{
      font-size: 14px;
    }
  }
  @include media-breakpoint-up(xl){

    .img{
      height: 50vw;
      background-size: 100%;
      background-position: center;
    }
    &-middle{
      bottom: calc(34% - 145px);
    }
  }

  @include media-breakpoint-down(md){

    .img{
      height: 131vw;
      background-size: 200%;
      background-position: bottom center;
    }
    &-middle{
      top: unset;
      bottom: calc(34% - 120px);
      padding-right: 10px;
      padding-left: 10px;
    }

    @include media-breakpoint-up(sm){

      .img{
        height: 60vw;
        background-size: 100%;
        background-position: bottom center;
      }
      &-middle{
        top: unset;
        bottom: calc(34% - 100px);
        max-width: initial;
        margin: 0;
        padding-right: 30px;
        padding-left: 30px;
      }

    }
    @include media-breakpoint-up(md){

      .img{
        height: 60vw;
        background-size: 100%;
        background-position: center;
      }
      &-middle{
        max-width: 60%;
        margin: 0 20%;
        display: block;
        padding: 0;
      }
    }
  }
}
.p-product{
  section {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 80px;
    }
  }
}

.text-block {
  .icons {
    a {
      + a {
        margin-left: 35px;
        img {
        }
      }
    }
  }
}