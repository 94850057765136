.product-card {


  section {
    margin-bottom: 50px;

    &.product-list {
      padding-bottom: 0;
    }

    &:last-child {
      margin-bottom: 80px;
    }
  }

  .item {
    //margin-bottom: 80px;
    .img {
      display: inline-block;
      width: 356px;
      max-width: 356px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
      }

      &-wrapper {
        height: 350px;
        display: flex;

        img {
          max-width: 294px;
          display: block;
          margin: auto;
          vertical-align: middle;
          max-height: 100%;
        }
      }
    }

    .desc {
      display: inline-block;
      vertical-align: top;
      margin-left: 30px;
      width: calc(100% - 390px);

      .top {
        display: flex;

        .price {
          display: inline-block;
          border-radius: 30px;
          border: $defBlueCol1 solid 1px;

          width: 135px;
          height: 125px;
          font: {
            size: 40px;
          };

          vertical-align: top;
          text-align: center;
          padding-top: 34px;
        }

        .buy {
          //border: 1px red solid;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;
          margin: 5px 0 0 20px;

          @media (max-width: 450px) {
            margin: auto;
          }

          .available {
            color: #32d14d;
            font-weight: bold;
            text-transform: none;
            font-size: 12px;
            font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
            position: relative;
            padding: 0 0 15px 0;
            padding-left: 15px;

            @media (max-width: 450px) {
              text-align: left;
              padding-left: 28px;
            }


            &:after {
              content: "✔";
              position: absolute;
              top: 0;
              left: 0;
              color: #32d14d;
            }

            &-not {
              color: red;
              font-weight: bold;
              text-transform: none;
              font-size: 12px;
              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
              padding: 0 0 15px 0;
            }
          }

          &-group {
            margin-right: 20px;

            @media (max-width: 450px) {
              margin: auto;
            }
          }

          .count {
            display: -webkit-flex;
            display: flex;

            &-btn {
              @extend .btn;

              display: inline-block;
              border-radius: 30px;
              border: $defBlueCol1 solid 1px;
              color: $defBlueCol1;
              width: 30px;
              height: 30px;

              position: relative;

              &::before {
                content: "";
                position: absolute;
                color: #a4d3ff;
              }

              &._add {
                &::before, &::after {
                  content: "";
                  position: absolute;
                  color: #a4d3ff;
                  background: #a4d3ff;
                  width: 13px;
                  height: 1px;
                  z-index: 2;
                  top: 49%;
                  left: 8px;
                }

                &::before {
                  transform: rotate(90deg);
                }
              }

              &._sub {
                &::before {
                  content: "";
                  background: #a4d3ff;
                  width: 13px;
                  height: 1px;
                  z-index: 2;
                  top: 49%;
                  left: 8px;
                }
              }
            }

            input {
              height: 30px;
              border: $defBlueCol1 solid 1px;

              margin-left: 8px;
              width: 67px;
              margin-right: 8px;
              text-align: center

            }
          }

          .btn-cart {
            margin-left: 21px;
          }

          .btn-buy {
            margin-left: 20px;

            @media (max-width: 450px) {
              margin: auto;
            }
          }
        }

        .goods-sum {
          margin: 10px 0 0 20px;
          font-size: 12px;
        }

        .instruction {
          @extend .text-small;
          text-align: left;
          margin-top: 8px;
          margin-left: 20px;

          &.lg-hidden {
            margin-left: auto;
            margin-right: auto;
          }

          a {
            //color: $defBlueCol;
          }
        }

      }

      .text {
        background: $defBlueCol4;
        border-radius: 30px;
        padding: 40px;
        margin-top: 30px;
        text-align: left;

        ul {
          list-style-type: none;
          padding-left: 0;

          li {
            background: url("../img/listItemStyle.png") left center no-repeat;
            padding-left: 20px;
          }
        }
      }

    }

    @include media-breakpoint-down(md) {
      text-align: center;
      .img-wrapper {
        display: block;
        float: none;
        margin: auto;
        width: 100%;
        max-width: 100%;
        height: auto;

        img {
          max-width: 100%;
          height: 230px;
        }
      }
      .desc {
        float: none;
        margin: auto;
        width: 100%;

        .top {
          margin-top: 60px;
          justify-content: space-around;
          flex-wrap: wrap;
          align-items: center;

          .buy {

            //

            @include media-breakpoint-down(sm) {
              flex-direction: column;
              margin: 10px 0 0;
              text-align: center;
              .btn-cart {
                margin: 10px 0;
              }
              .btn-buy {
                margin: 0;
              }
            }
          }

          .goods-sum {
            margin: 10px 0 0 10px;
          }

          .instruction.lg-hidden {
            text-align: center;
          }
        }
      }
    }
  }
}

.delivery-truck {
  margin-left: -50px;
  padding: 0 0 15px 22px;
  position: relative;
  color: #1e69b0;
  font-weight: 700;
  font-size: 12px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;

  @media (max-width: 450px) {
    margin-left: 0;
    margin-top: 15px;
    padding-bottom: 10px;
  }

  &:after {
    content: '';
    background: url("../../assets/img/delivery-truck-blue.png");
    background-size: 100% 100%;
    height: 16px;
    width: 16px;
    position: absolute;
    left: 0;
    top: 11%;
  }
}

.form-buy-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-end;

  .btn-buy {
    margin-top: 10px;
    //margin-bottom: 10px;

    @media (max-width: 450px) {
      margin: auto;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.recommendation-for-buy {
  display: block;
  padding-left: 20px;
  padding-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 3s ease-in-out;

  &.is-active {
    max-height: 1000px;
  }

  a {
    font-weight: bold;
    color: #1e69b0;
  }
}