@import "include/fonts";
@import "include/mixins";
@import "include/variables";
@import "include/sprites";
@import "bootstrap/bootstrap";

$bootstrap-sass-asset-helper: false;
$icon-font-name: 'glyphicons-halflings-regular';
$icon-font-svg-id: 'glyphicons_halflingsregular';
$icon-font-path: '../fonts/bootstrap/';
@import "bootstrap/glyphicons";

html {
  height: 100%;
  font-size: 14px;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      //filter: brightness(.8);
    }
  }
}

body {
  height: 100%;
}

.modal-open {
  padding-right: 17px !important;
  position: fixed;
  height: 100%;
  width: 100%;
  @include media-breakpoint-down(sm) {
    padding-right: 0 !important;
  }
}

.modal {
  padding-right: 0 !important;
}

.body-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body-content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

%text-mod {
  &._b {
    font-weight: 800;
  }

  &._center {
    text-align: center;
  }

  @each $color, $value in $theme-colors {
    &._#{$color} {
      color: $value !important;
    }
  }
}

h1 {
  font: {
    size: 60px;
    family: $font-rbt;
    weight: 400;
  }
  @extend %text-mod;
}

h2 {

  text-align: center;
  font: {
    size: 30px;
    family: $font-rbt;
    weight: 400;
  }
  @extend %text-mod;
}

h3 {
  text-align: left;
  font: {
    size: 18px;
    family: $font-rbt;
    weight: 400;
  }
  margin-bottom: 15px;

  @extend %text-mod;
}

h4 {
  text-align: left;
  font: {
    size: 16px;
    family: $font-rbt;
    weight: 400;
  }
  margin-bottom: 20px;

  @extend %text-mod;
}

p {
  @include font-14();

  &.item-caption {
    @include font-14();
    color: $defGrayCol;
  }

  ul {
    li {
      @include font-14();
    }
  }
}

.text {
  &-small {
    font: {
      family: $font-rbt;
      weight: 400;
      size: 12px;
    }

    p {
      font-size: 12px;
    }
  }
}

.far, .fas {
  margin-right: 2px;
}

hr {
  color: $defBlueCol1;
  background: $defBlueCol1;
}

.container {
  max-width: $container-max-widths;

  margin-left: auto;
  margin-right: auto;
}

.btn {
  border-radius: 30px;
  //padding: 3px 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: none;

  &-big {
    font: {
      size: 18px;
      family: $font-rbt;
      weight: 400;
    };
    padding: 10px 20px;
  }

  font {
    size: 14px;
  }

  &-green {
    @extend .btn-success;
  }

  span.glyphicon {
    font-size: 13px;
    margin-right: 3px;
  }
}


.dotted {
  text-decoration: none;
  //border-bottom-color: $defWhiteCol
  border-bottom-width: 1px;
  border-bottom-style: solid;

  display: inline-block;

  &.-dashed {
    border-bottom-style: dashed;
  }

  &.-solid {
    border-bottom-style: solid;
  }

  &.-black {
    border-bottom-color: $defBlackCol;
  }

}

.mobile-hidden, .md-down-hidden { // < md
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.sm-down-hidden {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.xs-down-hidden {
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.lg-hidden, .lg-up-hidden { // >= lg
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.md-up-hidden {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.sm-up-hidden {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

.xs-up-hidden {
  @include media-breakpoint-up(xs) {
    display: none !important;
  }
}

.form-group.has-error {
  input {
    border-color: red;
  }

  .help-block.error {
    text-align: center;
    font-size: 12px;
    color: red;
  }
}

.notifyjs-wrapper {
  z-index: 999 !important;
}

.subs {
  .input-group {
    .form-group {
      flex: 1;
      margin-bottom: 0;
    }

    .input-group-append {
      button {
        right: -1px;

        &:focus, &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.checkbox {
  text-align: center;

  .custom-check {
    display: inline-block;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 14px;
      height: 14px;
      border: 1px solid $defBlueCol0;
      border-radius: 2px;
      z-index: 0;
    }

    &:after {
      display: none;
    }

    &.activated {
      &:after {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 10px;
        display: block;
        position: absolute;
        left: 2px;
        top: 3px;
        z-index: 1;
        color: $defBlueCol0;
        cursor: pointer;
      }
    }

    input {
      opacity: 0;
      cursor: pointer;
    }
  }
}

.auth-user {
  display: flex !important;
  align-items: center;
}

.alert {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 25%;
  color: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #32d14d;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: none;

  .close {
    &:after, &:before {
      width: 14px;
      left: 5px;
    }
  }
}

.datepicker {
  z-index: 1100 !important;
}

.notifications {
  position: fixed;
  z-index: 9999;

  &.top-right {
    right: 0;
    top: 25%;
  }

  & > div {
    position: relative;
    background-color: #32d14d;
    color: #fff;
    padding: 10px 15px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

img.mfp-img {
  padding: 40px 40px;
  margin-top: 40px;
  background-color: #fff;
}

.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: $defBlueCol0;
}

.tooltip-inner {
  background-color: $defBlueCol0;
  opacity: 1;
}

.tooltip.show {
  opacity: 0.95;
}

.custom-select {
  display: block;
  text-align: center;
  text-align-last: center;
  opacity: .8;
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.anim-tada {
  animation: tada 2s infinite;

  &:hover {
    animation: none;
  }
}

.inf-btn {
  position: fixed;
  bottom: 40px;
  right: 0;
  padding: 6px 12px;
  background-color: red;
  color: #fff;
  z-index: 100;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 15px;
  font-weight: bold;

  &:hover {
    color: #fff;
  }

  @include media-breakpoint-down(xs) {
    font-size: 11px;
  }

  &:last-child {
    bottom: 80px;
  }
}

.title-sale {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 13%;
  background: #ff230b;
  padding: 0 2%;
  border-radius: 0.3em;
}

.title-sale.mobile {
  left: 110px;
  top: -15%;
  right: auto;
  width: 60px;
  text-align: center;
  padding: 0;
}


.home__top-swiper {
  &__item {
    @include media-breakpoint-down(xs) {
      height: auto !important;
    }
  }
  .img {
    img {
      @include media-breakpoint-down(xs) {
        height: auto !important;
      }
    }
  }
}