@mixin font-b-18{
  font: {
    family: $font-rbt;
    size: 18px;
    weight: 800;
  };
}
@mixin font-18{
  font: {
    family: $font-rbt;
    size: 18px;
    weight: 400;
  };
}

@mixin font-16{
  font: {
    family: $font-rbt;
    size: 16px;
    weight: 400;
  };
}
@mixin font-b-16{
  font: {
    family: $font-rbt;
    size: 16px;
    weight: 800;
  };
}
@mixin font-14{
  font: {
    family: $font-rbt;
    size: 14px;
    weight: 400;
  };
}
@mixin font-b-14{
  font: {
    family: $font-rbt;
    size: 14px;
    weight: 800;
  };
}
@mixin font-12{
  font: {
    family: $font-rbt;
    size: 12px;
    weight: 400;
  };
}
@mixin font-b-12{
  font: {
    family: $font-rbt;
    size: 12px;
    weight: 800;
  };
}

/**
 $col1,$col2: color
 $size: <number>px
 */
//@mixin wave-background($backCol: $defBlueCol2, $topCol: white, $size: 180px,$down: -120px){
//
//  background: $backCol;
//  //radial-gradient(circle, $topCol $size, transparent ($size + 1px))  0  ($down -$size),
//  //radial-gradient(circle, $backCol $size, transparent ($size + 1px))  (-$size) ($down + $size * 0.73),
//  //linear-gradient($topCol ($size * 0.9 + $down), $backCol ($size * 0.9 + $down) );
//
//  //background-size: ($size * 2) ($size * 2);
//  //background-repeat: repeat-x;
//  //background-color: $backCol;
//  &:before{
//
//    background: url('../img/wave-bg.svg');
//    color: red;
//
//    //-webkit-mask: url('../img/wave-bg.svg');
//    //mask-image: url('../img/wave-bg.svg');
//    width: 258px;
//    height: 36px;
//    content: "";
//    display: inline-block;
//    position: absolute;
//    top:0;
//  }
//}

@mixin setLinkColor($col: $defWhiteCol){
  color: $col;
  &:hover{
    color: darken($col, 15%);
    //filter: none;
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

