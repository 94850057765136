$fa-font-path:                "../webfonts" !default;
$fa-font-size-base:           16px !default;
$fa-css-prefix:               fa !default;
$fa-version:                  "5.2.0" !default;
$fa-border-color:             #eee !default;
$fa-inverse:                  #fff !default;
$fa-li-width:                 2em !default;


$fa-font-path: '../fonts/font-awesome';
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";