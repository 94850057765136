
.header._mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  height: auto;
  display: block;

  a{
    color: $defWhiteCol;
  }
  //height: 67px;
  .top {
    .burger-menu-icon {
      display: inline-block;
      float: left;
      margin-top: 20px;
      margin-left: 7px;
      margin-bottom: 20px;
      cursor: pointer;
      fill: $defWhiteCol;
    }
    .logo {
      display: block;
      float: none;
      padding: 9px 0 0 0;

      margin: 0;
      width: initial;
      img {
        width: 92px;
        margin: auto;
        display: block;
      }
    }
    .cart {
      float: right;
      margin-top: 14px;
      margin-right: 10px;
      @include media-breakpoint-down(lg){
        margin: 0;
      }
    }
  }

  .drop-down {
    background-color: $defBlueCol;
    color: $defWhiteCol;
    position: absolute;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    left:0;
    .login-wrapper {
      margin-top: 30px;
      margin-right: 0;
      margin-left: 0;
      padding: 0 0 0 1.25rem;
      .dotted{
        margin-right: 15px;
      }
      .col{

        padding: 0;
        &:nth-child(1) {
          max-width: 102px;
        }
        span {
           font-size: 14px;
           vertical-align: text-top;
          float: right;
          margin-right: 13px;
         }

      }

      button.login {
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;

        +span{
          margin-left: 10px;
        }
      }
    }
  }

  .list-group{
    background-color: $defBlueCol;
    margin-top: 21px;
    margin-bottom: 20px;
    &-item{
      background-color: $defBlueCol;
      border: none;
      border-radius: unset;
      padding-top: 4px;
      padding-bottom: 4px;
      a{
        font: {
          family: $font-rbt;
          weight: 400;
          size: 22px;
        }
        letter-spacing: 0.6px;
      }
    }
  }

}

.mobile-nav{
  flex-direction: column;
  .active{
    a{
      text-decoration: underline;
    }
  }
}

