.socials {
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  padding: 0;
  height: 100%;
  margin: 0 20px;

  &__item {
    &:nth-child(2) {
      margin-left: 15px;
    }
  }

  &__link {
    outline: none!important;
  }
}