
.swiper {
  .swiper-pagination {

    &._blue {
      .swiper-pagination-bullet {
        background: $defBlueCol;
      }
    }

    &-bullet {

      width: 14px;
      height: 14px;
      margin-top: -4px;
      margin-right: 8px;
      margin-left: 8px;


      background: $defWhiteCol;
      opacity: 1;

      &-active {
        opacity: 1;
        width: 14px;
        height: 14px;

        background: $defGreenCol !important;
        margin-top: 0;

        &-prev {
          opacity: 0.8;
          //background: $defWhiteCol;
          &-prev {
            //background: $defWhiteCol;
          }
        }

        &-next {
          //background: $defWhiteCol;
          opacity: 0.8;

          &-next {

            //background: $defWhiteCol;
          }
        }
      }
    }

  }

  .swiper-button {
    &-prev {
      z-index: 3;
      background-image: url('../img/swiperArrowLeft.png');
      width: 44px;
      background-size: 44px;

      &._green {
        background-image: url('../img/swiperArrowLeft_green.png');

      }
    }

    &-next {
      z-index: 3;
      background-image: url('../img/swiperArrowRight.png');
      width: 44px;
      background-size: 44px;

      &._green {
        background-image: url('../img/swiperArrowRight_green.png');

      }
    }
  }

  &-container._bottom-img {
    margin-top: 50px;

    .swiper-slide {

      &.js-img-active {
        .img-wrapper {
          &:after {
            content: '';
            position: absolute;
            display: block;
            height: 3px;
            width: 100%;
            bottom: -10px;
            left: 0;
            background-color: $defBlueDarkCol;
          }

          img {
            filter: opacity(0.5);
          }
        }
      }

      .product-list {
        padding-bottom: 0;

        .product-item {
          padding-bottom: 0;
          background: none;

          .product-inner {
            padding: 0;
            background: transparent;

            &:hover {
              background: none;
            }

            .img-wrapper {

              padding-right: 0;
              padding-bottom: 0;
              margin-bottom: 20px;
              width: 50px;
              height: 100px;
              display: flex;
              //max-width: 50px !important;
              //max-height: 100px !important;
              img {
                max-width: 50px !important;
                max-height: 100px !important;
              }
            }
          }
        }
      }
    }
  }

}


.input-group {
  &-text {
    @include font-14();
    line-height: 10px;
  }

  .input-group-append {
    .btn {
      @include font-14();
      line-height: 10px;

      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 10px;
      //
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  .form-control {
    //&:first-child{
    //  border-radius: 20px 0 0 20px; // todo
    //}

  }

  &.round {
    .form-control {
      border-radius: 20px;
      padding-right: 115px;
      z-index: 1;
    }

    .input-group-append {
      .btn {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        position: absolute;
        right: -1px;
        z-index: 3;
      }
    }
  }
}

form {

}

.form-group {
  label {
    color: $defGrayCol1;
    display: block;
    text-align: center;
  }
}

.form-control {
  height: 30px;
  @include font-14();
  border-radius: 30px;
}

textarea {
  &.form-control {
    border-radius: 10px;
  }
}

textarea, input {
  display: block;
  text-align: center;
}

input[type="checkbox"] {
  display: inline-block !important;
}

ul {
  @include font-14();

  &.breadcrumbs {
    text-align: center;
    list-style-type: none;
    color: $defBlackCol;
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    margin-bottom: 36px;

    @include media-breakpoint-down(xs) {

      display: flex;
      flex-wrap: wrap;
      text-align: left;
      li {
        //&:before{
        //  display: none;
        //}
      }
    }

    li {
      display: inline-block;
      color: $defBlueCol;

      font: {
        size: 12px;
        family: $font-rbt-r;
        weight: 400;
      };

      &:last-child {
        color: $defGrayCol;

        a {
          color: $defGrayCol;
        }
      }

      + li {
        &:before {
          content: "";
          padding: 0 20px;
          background: url("../img/breadcrumbsArrow.png") center 55% no-repeat;
        }
      }

      a {
        color: $defBlueCol;
        font: {
          size: 12px;
          family: $font-rbt-r;
          weight: 400;
        };
      }
    }
  }

  &._tilda {
    list-style-type: none;
    padding-left: 0;

    li {
      background: url("../img/listItemStyle.png") left center no-repeat;
      padding-left: 20px;
    }

    &.pos-top {
      li {

        background-position-y: 7px;
      }
    }
  }
}

.title {
  @extend h2;
  color: $defBlackCol;
  background: url("../img/titleLine.png") center bottom no-repeat;
  padding-bottom: 30px;
  margin: 50px auto 50px;
  max-width: 550px;

  &.-white {
    color: $defWhiteCol;
    background-image: url("../img/titleLine_white.png");
  }

}

.subs {
  border-radius: $form-border-radius;
  //height: 80px;
  background: $defBlueCol4;
  padding: 30px;
  //padding-top: 30px;
  //padding-bottom: 30px;
  .input-group.round {
    .form-control {
      border-radius: 20px;
    }
  }

  .text {
    @include font-18();
    display: inline-block;
    vertical-align: middle;

    .b {
      font-weight: 800;
      display: inline-block;
    }

  }

  @include media-breakpoint-down (md) {
    height: auto;
    .row {
      flex-direction: column;
    }
    .col {
      flex-basis: auto;
    }
    .envelope {
      margin: 0 auto 20px;
      display: block;
    }
    .top {
      text-align: center;
      margin-bottom: 20px;

      .b {
        display: block;
      }
    }
    .input-group {
      margin-bottom: 20px;

      input {
        padding-right: 15px !important;
        flex-grow: 1;
        flex-shrink: initial;
        flex-basis: initial;
      }
    }
  }
}

.text-block {
  //  margin-top: 60px;
  //  margin-bottom:80px;
  p {
    @include font-14();

    &:last-child {
      margin-bottom: 0;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.dotted {
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: dashed;

    &:hover {
      text-decoration: none;
      //color: darken($defWhiteCol,15%);
      //border-bottom: 1px dashed darken($defWhiteCol,15%);
    }

    &._blue, &._primary {
      color: $defBlueCol;
      border-bottom-color: $defBlueCol;

      &:hover {
        color: $defBlueCol1;
        border-bottom-color: $defBlueCol1;
      }
    }

    //&._white,&._light{
    //  color: $defWhiteCol;
    //  border-bottom-color: $defWhiteCol;
    //  &:hover {
    //    color: $defGrayCol1;
    //    border-bottom-color: $defGrayCol1;
    //  }
    //}
    &._solid {
      border-bottom-style: solid;

      &:hover {
        border-bottom-style: solid;
      }
    }
  }

  &.inactive {

  }
}

.btn {
  &.btn-lg {
    padding: 17px 60px;
    font-size: 18px;
    line-height: 14px;
  }
}


.paginator {
  margin: 80px auto;
  display: block;
  float: none;
  text-align: center;

  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  > ul {
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      list-style: none;
      margin: 5px 5px;
      font-size: 14px;
      text-align: center;

      > a {
        @include font-18();
        text-decoration: none;
        display: block;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 50%;

        height: 30px;
        width: 30px;
        //line-height: 28px;
        top: -1px;
        left: -1px;

        color: $defBlueCol;

      }

      &.active {
        > a {
          color: $defWhiteCol;
          background-color: $defGreenCol;


        }
      }

      &:hover {
        > a {
          border-color: $defBlueCol;
        }
      }
    }
  }
}

.img {
  &-wrapper {
    &._v-center {

      display: flex;
      align-items: center;
      //justify-content: center;
      div {

        max-width: 100%;

        img {

        }
      }
    }
  }

  &-crop {

  }
}


.feedback {
  background: $defBlueCol4;
  margin: 40px 0;
  padding: 40px;
  border-radius: 30px;
  text-align: center;

  h3 {
    margin-bottom: 35px;
  }

  .form-control {
    display: block;
    position: relative;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 15px;
  }
}

.cart {
  .cart-count {
    position: relative;
    display: block;

    i {
      font-size: 21px;
      line-height: 30px;
    }

    .cart-btn {
      @extend .btn, .btn-success;
      @include font-12();

      line-height: 16px;
      position: absolute;
      margin-top: 0;
      left: 12px;
      top: -6px;
      padding: 0 6px;

      @include media-breakpoint-down(md) {
        margin-left: 10px;
        left: 6px;
      }
    }
  }
}

.count-btn {
  @extend .btn;

  display: inline-block;
  border-radius: 30px;
  border: $defBlueCol1 solid 1px;
  color: $defBlueCol1;
  width: 30px;
  height: 30px;

  &._primary {
    border-color: $primary;
    color: $primary;

    &._add {
      &::before, &::after {
        color: $primary;
        background-color: $primary;
      }
    }

    &._sub {
      &::before {
        background-color: $primary;
      }
    }
  }

  position: relative;

  &::before {
    content: "";
    position: absolute;
    color: #a4d3ff;
  }

  &._add {
    &::before, &::after {
      content: "";
      position: absolute;
      color: $defBlueCol1;
      background: $defBlueCol1;
      width: 13px;
      height: 1px;
      z-index: 2;
      top: 49%;
      left: 8px;
    }

    &::before {
      transform: rotate(90deg);
    }
  }

  &._sub {
    &::before {
      content: "";
      background: $defBlueCol1;
      width: 13px;
      height: 1px;
      z-index: 2;
      top: 49%;
      left: 8px;
    }
  }

  + span {
    margin-left: 20px;
  }
}

.product-count {
  display: -webkit-flex;
  display: flex;
  justify-content: center;

  input {
    height: 30px;
    border: $defBlueCol1 solid 1px;

    margin-left: 8px;
    width: 67px;
    margin-right: 8px;
    text-align: center

  }
}

.close {

  height: 20px;
  width: 20px;
  position: relative;

  &::before, &::after {
    content: "";
    position: absolute;
    //color: $def;
    background: $defBlueCol;
    width: 22px;
    height: 2px;
    z-index: 2;
    top: 50%;
    left: 0;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

div.form-check {

  padding-left: 0;

  &.small {
    font-size: 12px;
    color: $defBlackCol1;
  }

  [type="checkbox"] {
    position: absolute;
    left: -999999px;

    + label {
      position: relative;
      padding-left: 22px;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 7px);
        width: 14px;
        height: 14px;
        border: 1px solid $primary;
        background: #fff;
        border-radius: 2px;
        //box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
      }

      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f00c';

        position: absolute;
        top: calc(50% - 4px);
        left: 3px;
        font-size: 9px;
        line-height: .8;
        color: $primary;
        transition: all .2s;
        //font-family: Helvetica, Arial, sans-serif;
      }

      &:hover {
        &:before {
          box-shadow: 0 1px 3px rgba(33, 143, 193, 0.6);
        }
      }
    }

    &:not(:checked) {
      + label:after {
        opacity: 0;
        transform: scale(0);
      }
    }

    &:checked {
      &:focus {
        + label:before {
          border-color: $defBlueDarkCol;
        }
      }

      + label:after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:disabled {
      + label:before {
        box-shadow: none;
        border-color: $defGrayCol;
        //background-color: #ddd;
      }

      &:checked + label:after {
        color: darken($defGrayCol, 10%);
      }

      + label {
        color: $defGrayCol;
      }
    }

  }

}

.flex {
  &-center {
    justify-content: center;
  }
}

.mar {
  &-t {
    &-40 {
      margin-top: 40px;
    }
  }
}

i.fa {
  margin-right: 5px;
}

img {
  &.icon {
    width: 1rem;
    margin-top: -4px;
  }
}

svg {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  fill: currentColor;
  stroke: inherit;

  &.burger {
    height: 24px;
    width: 24px;
  }

  &.wave-bg {
    width: 100%;
    position: absolute;
    top: -6px;
    left: 0;
    height: 45px;
    fill: $defWhiteCol;
  }
}

.icon {
  vertical-align: middle;

  // svg in input
  &-container {
    position: relative;

    .icon {
      position: absolute;
      bottom: 8px;
      right: 16px;
      height: 16px;
      width: 16px;
      cursor: pointer;

    }
  }

  &-primary {
    color: $primary;
  }
}

i {
  &.icon {
    &_md {
      font-size: 24px;
      max-width: 24px;
    }
  }
}

svg {
  &.icon {
    &_md {
      height: 24px;
      width: 24px;
    }
  }
}


.float {
  &-left {
    float: left;
  }

  &-right {
    float: right;
  }
}

.fa- {
  //&-1_75{
  //
  //}
}