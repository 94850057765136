.p-delivery{
	.info{
		ol {
			margin: 0 0 20px;
			padding: 0;
			counter-reset: item;
		}

		ol > li {
			margin: 0;
			padding: 0 0 0 30px;
			text-indent: -30px;
			list-style-type: none;
			counter-increment: item;
		}

		ol > li:before {
			display: inline-block;
			width: 30px;
			padding-right: 8px;
			font-weight: bold;
			text-align: right;
			content: counter(item) ".";
		}
	}
}

.timetable{
	width: 100%;
	font-size: 16px;
	margin-bottom: 20px;
	overflow-x: auto;
	table{
		min-width: 600px;
		width: 100%;
		thead{
			color: #1e69b0;
			font-weight: bold;
		}
		td{
			border-top: none;
			position: relative;
			border-bottom: 1px solid #ccc;
			display: table-cell;
			vertical-align: middle;
			span{
				font-size: 14px;
			}
		}
		td:first-child{
			width: 30%;
		}
		td:not(:first-child){
			width: 10%;
		}
		td:not(:last-child){
			border-right: 1px solid #ccc;
		}
	}
}